import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserReducer } from 'store/reducers/UserReducer';
import _ from 'lodash';
import { Subscription } from 'store/types/Subscriptions';
import moment from 'moment';
import { DateFormat } from 'values/values';
import Utility from 'utils/utils';
import SubscriptionPlanRadioButton from 'components/subscription/SubscriptionPlanRadioButton';
import { Plan } from 'store/types/Plan';
import strings from 'values/strings';
import PlanService from 'services/PlanService';

type Props = {
    sub: Subscription
    onPress: (plan: Plan | undefined) => void
    onCancel: () => void
}

const SubscriptionsEdit: React.FC<Props> = ({ sub, onPress, onCancel }) => {

    const [plansInfoResponse, plansInfoError, plansInfoRequest] = PlanService.usePlansInfoAPI()

    const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);

    const radioButton = strings.subscriptions.radioButton;

    const [selectedPlan, setSelectedPlan] = useState<Plan>();
    const [currentPlan, setCurrentPlan] = useState<Plan>();


    useEffect(() => {
        plansInfoRequest(sub.id!)
    }, [])

    useEffect(() => {
        if (plansInfoResponse != undefined) {
            setCurrentPlan(_.first(plansInfoResponse.filter((p) => p.is_active == true)))

        }
    }, [plansInfoResponse])

    const title = (plan: Plan) => {
        if (plan.percentage == null) return `${plan.months} mesi`
        return `${plan.months} mesi (-${plan.percentage}%)`
    }

    const getOiginalPrice = (plan: Plan) => {
        return Number(plan.amount) / (1 - plan.percentage! / 100)
    }

    const isSelected = (plan: Plan) => {
        return (selectedPlan != undefined && selectedPlan.id) == plan.id
    }

    const getFinalPrice = (plan: Plan) => {
        if (plan != null) {
            let costPerMonths = plan.amount! * Number(plan.months)
            let subTotal = costPerMonths - (costPerMonths / 100 * Number(plan.percentage))
            return subTotal
        } else return null
    }

    return (
        <div className="safe-area-container">
            {currentUser && currentUser.subscriptions && <div className="view-container" style={{ padding: 20, width: 400 }}>
                <div>
                    <h2>{'Modifica piano'}</h2>
                    <h3 className={'title'} style={{ marginTop: 24, marginBottom: 16 }}>{'Il tuo piano attuale'}</h3>
                    {currentPlan && <SubscriptionPlanRadioButton
                        key={currentPlan!.id}
                        id="annual"
                        title={title(currentPlan!)}
                        subtitle={radioButton.automaticAnnualRenewal}
                        // originalPrice={getOiginalPrice(currentPlan) == getFinalPrice(currentPlan) ? '' : `${Utility.currency(getOiginalPrice(currentPlan))}`}
                        discountedPrice={`${Utility.currency(currentPlan.amount)} + IVA`}
                        isActive={true}
                        onClick={() => { }}
                    />}

                    <h3 className={'title'} style={{ marginTop: 40 }}>{'Scegli un nuovo piano'}</h3>

                    {/* EmptyState */}
                    {plansInfoResponse && _.isEmpty(plansInfoResponse.filter((p) => p.is_active == false)) &&
                        <p className='description' style={{ textAlign: 'center', color: '#82849D', marginTop: 40, fontSize: 16 }}>{'Nessun piano disponibile. Ti invitiamo a contattare l\'amministratore.'}</p>
                    }


                    {/* List Plans */}
                    <div className="subscription-plan-container__radio-buttons">
                        {plansInfoResponse && !_.isEmpty(plansInfoResponse.filter((p) => p.is_active == false)) &&
                            <p className="description" style={{ marginTop: 8, marginBottom: 20 }}>
                                <span style={{color: '#82849d'}}>Il nuovo piano sarà attivo dal <b style={{color: '#208a86'}}>{moment(sub!.expire_at).format(DateFormat.full)}</b> ed il pagamento avverrà tramite <b style={{color: '#208a86'}}>bonifico bancario</b>.</span><br/>
                                <span style={{color: '#82849d'}}>Il giorno del rinnovo riceverai la fattura con l'IBAN per il pagamento.</span>
                            </p>
                        }

                        {plansInfoResponse && plansInfoResponse.filter((p) => p.is_active == false).map((plan) => {
                            return <SubscriptionPlanRadioButton
                                key={plan.id}
                                id="annual"
                                title={title(plan)}
                                subtitle={radioButton.automaticAnnualRenewal}
                                originalPrice={getOiginalPrice(plan) == getFinalPrice(plan) ? '' : `${Utility.currency(getOiginalPrice(plan))}`}
                                discountedPrice={`${Utility.currency(plan.amount)} + IVA`}
                                isActive={isSelected(plan)}
                                onClick={() => setSelectedPlan(plan)}
                            />
                        })}
                    </div>
                </div>

                <div className="row" style={{ justifyContent: 'space-between' }}>
                    <div className="button-container" onClick={() => {
                        onCancel()
                    }}>
                        <button className="button-secondary">Annulla</button>
                    </div>

                    <div className="button-container" onClick={() => {
                        console.log("CONFIRM PLAN", selectedPlan)
                        onPress(selectedPlan)
                    }}>
                        <button disabled={selectedPlan == undefined} className={'button-primary'}>Conferma piano</button>
                    </div>
                </div>

            </div>}
        </div >
    );
}

export default SubscriptionsEdit;