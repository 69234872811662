import React, { useEffect, useState } from "react";
import Input from "components/common/Input";
import { arrowSelect } from "../../image/icons";
import strings from "values/strings";
import UserService from "services/UserServices";
import { SubscriptionInfo } from "store/types/SubscriptionInfo";
import { useDispatch } from "react-redux";
import Utility from "utils/utils";
import { setPromoPercentage, setPromoDiscount, setPromoCode } from "store/reducers/SubscriptionReducer";
import { Plan } from "store/types/Plan";

interface Props {
  subscription: SubscriptionInfo;
  plan?: Plan
}

const AccordionPromo: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [_promoCode, _setPromoCode] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const promoStrings = strings.subscriptions.accordionPromo
  const [validateCouponCodeResponse, validateCouponCodeError, validateCouponCodeRequest] = UserService.useValidateCouponCodeAPI()
  const dispatch = useDispatch()

  useEffect(() => {
    if (validateCouponCodeResponse) {
      var message = ''

      if (validateCouponCodeResponse.percentage) {
        dispatch(setPromoPercentage(validateCouponCodeResponse.percentage))
        message = `Grazie al codice ${validateCouponCodeResponse.code} è stato applicato uno sconto del ${validateCouponCodeResponse.percentage} %`

      } else if (validateCouponCodeResponse.discount) {
        dispatch(setPromoDiscount(validateCouponCodeResponse.discount))
        message = `Grazie al codice ${validateCouponCodeResponse.code} è stato applicato uno sconto di ${Utility.currency(Number(validateCouponCodeResponse.discount))}.`
      }

      dispatch(setPromoCode(_promoCode))
      setValidationMessage(message);
      setIsValid(true);
    }
  }, [validateCouponCodeResponse])

  useEffect(() => {
    if (validateCouponCodeError) {
      setValidationMessage(validateCouponCodeError.message);
      setIsValid(false);

      dispatch(setPromoCode(''))
      dispatch(setPromoPercentage(0))
      dispatch(setPromoDiscount(0))
    }
  }, [validateCouponCodeError])


  useEffect(() => {
    console.log("SELECTED PLAN", props.plan)
    reset()
  }, [props.plan])



  const reset = () => {
    // setIsOpen(false)
    _setPromoCode('')
    setValidationMessage('')
    setIsValid(null);
    // Reset reducer
    dispatch(setPromoCode(''))
    dispatch(setPromoPercentage(0))
    dispatch(setPromoDiscount(0))

  }

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setValidationMessage('');
      setIsValid(null);
    }
  };

  const handlePromoChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      _setPromoCode(event.target.value);
    }
  };

  const validatePromoCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.subscription != undefined && props.plan != undefined) {
      validateCouponCodeRequest(_promoCode, `${props.subscription.id}`, `${props.plan.id}`)
    }
  };

  return (
    <div className="accordion-promo">
      <div className="accordion-promo__header" onClick={toggleAccordion}>
        <span>{promoStrings.title}</span>
        <span
          className={`accordion-promo__icon ${isOpen ? "accordion-promo__icon--open" : ""
            }`}
        >
          <img src={arrowSelect} alt={"seleziona"} />
        </span>
      </div>
      {isOpen && (
        <div className="accordion-promo__content">
          <form>
            <div className="accordion-promo__input-wrapper">
              <Input
                type="text"
                value={_promoCode}
                onChange={handlePromoChange}
                placeholder={promoStrings.placeholder}
              />
              <button
                type="submit"
                onClick={validatePromoCode}
                className="accordion-promo__validate-button"
                disabled={!_promoCode}
              >
                {promoStrings.validatePromoBtn}
              </button>
            </div>
          </form>
          {isValid !== null && (
            <p
              className={`accordion-promo__message ${isValid
                ? "accordion-promo__message--success"
                : "accordion-promo__message--error"
                }`}
            >
              {validationMessage}
            </p>
          )}
        </div>
      )}
    </div>

  );
};

export default AccordionPromo;
