import React, { FC, useState, useEffect } from 'react';
import { User } from 'store/types/User';
import { Skill } from 'store/types/Profile';
import EmptySpecificSkillBanner from './EmptySpecificSkillBanner';
import UserService from 'services/UserServices';
import strings from 'values/strings';
import { useHistory } from 'react-router-dom';
import { SubscriptionInfoType } from 'values/values';

type SpecificSkillsBannerProps = {
  currentUser: User;
}

const SpecificSkillsBanner: FC<SpecificSkillsBannerProps> = ({ currentUser }) => {
  const [specificSkillBanner, setSpecificSkillBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [skillsResponse, , skillsRequest] = UserService.useSearchSkillsAPI();
  let history = useHistory()

  useEffect(() => {
    skillsRequest('');
  }, []);

  useEffect(() => {
    if (skillsResponse) setIsLoading(false);
  }, [skillsResponse]);

  const specificSkills = currentUser.profile.skills.filter((item) => item.type === 'ADDITIONAL_SKILLS') || [];

  return (
    <>
      {specificSkills.length < 1 ? (
        <EmptySpecificSkillBanner
          isForMainSkill={false}
          action={() => setSpecificSkillBanner(true)}
        />
      ) : (
        <div className={"filled-banner-container"}>
          <div className={"filled-banner-header"}>
            <span>Competenze ulteriori</span>
          </div>
          <div className={"skill-container"}>
            {specificSkills.map((skill: Skill) => {
              return (
                <span key={skill.id} className={"skill-banner"}>
                  {skill.name}
                </span>
              );
            })}
          </div>
          <div className="add-skill-button">
            <button onClick={() => setSpecificSkillBanner(true)}>
              {strings.profile.basicCoWorker.addSkillButton}
            </button>
          </div>
        </div>
      )}

      {specificSkillBanner && !isLoading && (
        history.push(`/subscription?type=${SubscriptionInfoType.SKILLS}`)
      )}
    </>
  );
};

export default SpecificSkillsBanner;