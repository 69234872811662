import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Network, { NetworkMethod } from '../network/Network';
import { setIsLoading } from '../store/reducers/AppReducer';
import { Plan } from 'store/types/Plan';


class PlanService {

    static usePlansInfoAPI = (): [Array<Plan>, any, (paymentId: number) => void] => {
        const [response, setResponse] = useState();
        const [error, setError] = useState();

        const dispatch = useDispatch();

        const request = async (paymentId: number) => {
            dispatch(setIsLoading(true));

            const method = NetworkMethod.get;
            const endpoint = `payments/${paymentId}/plans-info/`;
            

            const response = await Network.fetchAPI(method, endpoint, true, null, null);
            dispatch(setIsLoading(false));

            setError(response.error);

            if (response.data) {
                setResponse(response.data);
               
            }
        };

        return [response, error, request];
    };



    static useChangePlansAPI = (): [any, any, (paymentId: number, planId: number) => void] => {
        const [response, setResponse] = useState();
        const [error, setError] = useState();
        const dispatch = useDispatch()

        const request = async (paymentId: number, planId: number) => {
            dispatch(setIsLoading(true));
            const method = NetworkMethod.put;
            const endpoint = `payments/${paymentId}/change-plan/`;
            const data = {
                plan_id: planId
            };

            const response = await Network.fetchAPI(method, endpoint, true, data, null);
            dispatch(setIsLoading(false))
            setError(response.error);

            if (response.data) {
                setResponse(response.data);
            }
        };

        return [response, error, request];
    };




}

export default PlanService;
